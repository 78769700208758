<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">발신프로필 관리</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">발신프로필 목록</h3>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <base-input additional-classes="width-100">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </base-input>
              <div class="form-inline" style="margin-bottom: 1.5rem;">
                <div class="col-lg-2 pr-0">
                  <el-select v-model="search.status"
                             filterable
                             placeholder="상태"
                             @change="retrieveProfileList(1)">
                    <el-option v-for="option in statusOptions"
                               :key="option.value"
                               :label="option.label"
                               :value="option.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="col-lg-2 pr-0">
                  <el-select v-model="search.dormant"
                             filterable
                             placeholder="휴면여부"
                             @change="retrieveProfileList(1)">
                    <el-option v-for="option in dormantOptions"
                               :key="option.value"
                               :label="option.label"
                               :value="option.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="col-lg-2 pr-0">
                  <el-select v-model="search.block"
                             filterable
                             placeholder="차단여부"
                             @change="retrieveProfileList(1)">
                    <el-option v-for="option in blockOptions"
                               :key="option.value"
                               :label="option.label"
                               :value="option.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="col-lg-2 pr-0">
                  <el-select v-model="search.condition"
                             filterable
                             placeholder="검색 조건">
                    <el-option v-for="option in selectOptions"
                               :key="option.label"
                               :label="option.label"
                               :value="option.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="col-lg-4 pl-1 pr-0">
                  <base-input group :input-group-classes="'width-100p'">
                    <input type="text" class="form-control" placeholder="검색어" aria-label="검색어 입력" aria-describedby="button-addon2"
                           v-model="search.keyword"
                           @keydown.enter="retrieveProfileList(1)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary" type="button" @click="retrieveProfileList(1)">검색</button>
                    </div>
                  </base-input>
                </div>
              </div>
            </div>
            <el-table :data="profileList"
                      :header-row-class-name="'thead-light'"
                      :header-cell-style="{'font-weight':'bold','font-size':'0.85rem'}"
                      :empty-text="'결과가 없습니다.'"
                      class="table-responsive table-flush">
              <el-table-column label="번호" min-width="7%">
                <template slot-scope="scope">
                  {{ pagination.total - (pagination.currentPage-1)*pagination.perPage-scope.$index }}
                </template>
              </el-table-column>
              <el-table-column prop="channelId" label="채널ID" min-width="10%"></el-table-column>
              <el-table-column prop="channelName" label="채널명" min-width="15%"></el-table-column>
              <el-table-column prop="senderKey" label="발신프로필키" min-width="25%"></el-table-column>
              <el-table-column prop="categoryName1" label="대분류" min-width="10%">
                <template slot-scope="scope">{{ categoryName(0,scope.row.categoryCode) }}</template>
              </el-table-column>
              <el-table-column prop="categoryName2" label="중분류" min-width="10%">
                <template slot-scope="scope">{{ categoryName(1,scope.row.categoryCode) }}</template>
              </el-table-column>
              <el-table-column prop="categoryName3" label="소분류" min-width="10%">
                <template slot-scope="scope">{{ categoryName(2,scope.row.categoryCode) }}</template>
              </el-table-column>
              <el-table-column prop="status" label="상태" min-width="8%">
                <template slot-scope="scope">{{ scope.row.status | statusFilter }}</template>
              </el-table-column>
<!--              <el-table-column prop="status" label="상태" min-width="8%">-->
<!--                <template slot-scope="scope">{{ scope.row.status | statusFilter }}</template>-->
<!--              </el-table-column>-->
              <el-table-column prop="status" label="휴면상태" min-width="9%" v-if="session.clientType === 'R'">
                <template slot-scope="scope">
                  <div class="align-items-center" v-if="scope.row.dormant == true">
                    <base-button type="danger" @click="recoverProfile(scope.row.senderKey)" size="sm">해제</base-button>
                  </div>
                  <div v-else>
                    정상
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="차단상태" min-width="9%" v-if="session.clientType === 'R'">
                <template slot-scope="scope">{{ scope.row.block | blockFilter }}</template>
              </el-table-column>
              <el-table-column prop="regDate" label="등록일시" min-width="14%">
                <template slot-scope="scope">{{ scope.row.regDate | convertDatetimeFormat }}</template>
              </el-table-column>

            </el-table>
          </div>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                총 <span class="text-danger font-weight-bold">{{ total }}</span> 건
              </p>
            </div>

            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @input="retrieveProfileList">
            </base-pagination>
          </div>

        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import { Table, TableColumn, Select, Option } from 'element-ui'
  import commJsUtilMixin from '@/shared/mixins/commJsUtil'
  import { default as commFlatpickrMixin } from '@/shared/mixins/commFlatpickrMixin'
  import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
  import { USER_API_PREFIX , RESULT_CODE} from '@/shared/util/const'
  import swal from "sweetalert2";
  import {mapGetters} from "vuex";

  export default {
    name: 'ProfileList',
    mixins: [ commFlatpickrMixin, commJsUtilMixin ,commPaginationMixin],
    components: {
      [Select.name] : Select,
      [Option.name] : Option,
      [Table.name]  : Table,
      [TableColumn.name]: TableColumn
    },
    computed: {
      ...mapGetters({
        session: 'SessionStore/getSession'
      })
    },
    filters: {
      statusFilter: value => {
        let name = '';
        switch (value) {
          case 'A':
            name = '정상';
            break;
          case 'S':
            name = '차단';
            break;
          case 'D':
            name = '삭제';
            break;
        }
        return name;
      },
      blockFilter: value => {
        let name = '';
        switch (value) {
          case true:
            name = '차단';
            break;
          default:
            name = '정상';
            break;
        }
        return name;
      }
    },
    created() {
      this.init();
    },
    data() {
      return {
        profileList : [] ,
        categoryListToMap: null,
        search  : {
          condition: 'CHANNEL_ID',
          keyword: '',
          status : '',
          dormant : null,
          block : null
        },
        statusOptions:[
          {
            label:'=상태=',
            value:''
          },
          {
            label: '정상',
            value: 'A'
          },
          {
            label: '삭제',
            value: 'D'
          }
        ],
        dormantOptions:[
          {
            label:'=휴면여부=',
            value: null
          },
          {
            label: '정상',
            value: 0
          },
          {
            label: '휴면',
            value: 1
          }
        ],
        blockOptions:[
          {
            label:'=차단여부=',
            value: null
          },
          {
            label: '정상',
            value: 0
          },
          {
            label: '차단',
            value: 1
          }
        ],
        selectOptions: [
          {
            label: '채널ID',
            value: 'CHANNEL_ID'
          },
          {
            label: '채널명',
            value: 'CHANNEL_NAME'
          },
          {
            label: '발신프로필키',
            value: 'SENDER_KEY'
          }
        ]
      }
    },
    methods: {
      async init() {
        await this.$axios.get(`${USER_API_PREFIX}/sender/category-list/map`, {
        }).then(res=> {
          if(res.data.result.code === RESULT_CODE.SUCCESS) {
            this.categoryListToMap = res.data.result.categoryList;
          } else {
            this.notify('warning', res.data.result.message);
          }
        });
        this.retrieveProfileList(1);
      },
      /**
       * 발신프로필 목록조회
       */
      retrieveProfileList(page) {
        let pageNo = page ? page : this.pagination.currentPage;
        this.$axios.get(`${USER_API_PREFIX}/sender/profile-list`, {
          params : {
            searchKeyword: this.search.keyword,
            searchCondition : this.search.condition,
            blockYn : this.search.block !== null ? 'Y' : '',
            dormantYn : this.search.dormant !== null ? 'Y' : '',
            dormant  : this.search.dormant,
            block    : this.search.block,
            status    : this.search.status,
            page      : pageNo,
            rows      : this.pagination.perPage
          }
        }).then(res => {
          this.profileList            = res.data.result.profileList;    // 목록정보 세팅
          this.pagination.currentPage = pageNo;                         // 페이지 번호 세팅
          this.pagination.total       = res.data.result.total;          // 전체페이지수 세팅
        });
      },

      /**
       * 카테고리명 조회
       */
      categoryName(step , code) {
        if(code !== null && code !== 'undefined' && code !== ''){
          let nameArr = this.categoryListToMap[code].name.split(',');
          return nameArr[step];
        }else{
          return '';
        }
      },

      /**
       * 휴면 해제 이벤트 실행
       */
      recoverProfile(senderKey) {
        this._swalQuestion({ title:'휴면 해제 하시겠습니까?' }, () => {
          this.$axios.put(`${USER_API_PREFIX}/sender/recover`, {
            senderKey: senderKey
          }).then(res => {
            if (res.data.result.code === RESULT_CODE.SUCCESS) {
              this.notify('default', res.data.result.message);
              this.retrieveProfileList();
            } else {
              this.notify('warning', res.data.result.message);
            }
          });
        });
      },

      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },

      /**
       * 알럿메시지 출력
       */
      alertMsg(msg , title){
        let titleMsg = title ? title : '경고';
        let type = title ? 'info' : 'warning';
        swal({
          title: titleMsg,
          text: msg,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-'+type,
          type: type,
          width: 400
        });
      },
    }
  }
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
